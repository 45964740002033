import { IBatch } from '../../interfaces/batch.interface';
import { batchTypes } from '../types/batch.types';

export const batchSearchRequest = (id: string | number, recaptcha: string | null, callback: (id: number) => void) => ({
    type: batchTypes.BATCH_SEARCH_REQUEST,
    payload: { callback },
    request: {
        url: `/batches/${id}/`,
        method: 'GET',
        params: { recaptcha }
    }
});

export const batchRecaptchaUpdate = (token: string | null) => ({
    type: batchTypes.BATCH_RECAPTCHA_UPDATE,
    payload: token
});

export const batchSearchSuccess = (data: IBatch) => ({
    type: batchTypes.BATCH_SEARCH_SUCCESS,
    payload: data
});

export const batchSearchReset = () => ({
    type: batchTypes.BATCH_SEARCH_RESET
});

export const batchSearchFailure = (error: string) => ({
    type: batchTypes.BATCH_SEARCH_FAILURE,
    payload: error
});

export const batchGetDocTypesRequest = () => ({
    type: batchTypes.BATCH_GET_DOCTYPES_REQUEST,
    request: {
        url: '/documents-type/',
        method: 'GET'
    }
});

export const batchGetDocTypesSuccess = (data: IBatch) => ({
    type: batchTypes.BATCH_GET_DOCTYPES_SUCCESS,
    payload: data
});

export const batchGetDocTypesFailure = (error: string) => ({
    type: batchTypes.BATCH_GET_DOCTYPES_FAILURE,
    payload: error
});
