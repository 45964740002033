import axios, { AxiosInstance } from 'axios';
import { API_URL } from './constants';

const apiCall = axios.create({
    baseURL: process.env.REACT_APP_API_URL || API_URL
});

apiCall.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME || '');
        config.headers['Content-Type'] = 'application/json';
        if (token && !config.url?.includes('login')) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default apiCall as AxiosInstance;
