import styled from 'styled-components';
import Search from '../../assets/images/search-logo.png';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #122a4c;
    display: flex;
    @media (max-width: 800px) {
        flex-direction: column-reverse;
    }
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 20px;
    left: 85%;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    @media (max-width: 760px) {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
`;

export const ImageBlock = styled.div`
    height: 100%;
    width: 40%;
    background-image: url(${Search});
    background-position: center;
    background-size: cover;
    @media (max-width: 800px) {
        width: 100%;
        margin-bottom: -100px;
    }
`;

export const ContentWrapper = styled.div`
    width: 60%;
    height: 100%;
    padding: 4% 0;
    @media (max-width: 800px) {
        padding: 0 4% 4% 4%;
        width: 100%;
    }
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    padding: 12%;
    background-color: #ffffff;
    margin-left: 8.6%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 3px 20px #00000029;
    position: relative;

    @media (max-width: 1120px) {
        padding: 8%;
    }
    @media (max-width: 800px) {
        margin-left: 0;
        padding: 6%;
    }
    @media (max-width: 500px) {
        padding: 14px;
        height: auto;
    }
`;

export const Logo = styled.img`
    width: 210px;
    height: auto;
    margin-bottom: 60px;
    @media (max-width: 800px) {
        width: 33%;
        margin-top: 25px;
    }
    @media (max-height: 400px) {
        margin-bottom: 10px;
        margin-top: 0;
    }
`;

export const Title = styled.div`
    color: #122a4c;
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    @media (max-width: 1120px) {
        font-size: 40px;
    }
    @media (max-width: 820px) {
        font-size: 26px;
    }
    @media (max-width: 500px) {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 6px;
    }
`;

export const Subtitle = styled.div`
    color: #4d4d4d;
    max-width: 60%;
    line-height: 25px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 50px;
    @media (max-width: 1120px) {
        font-size: 14px;
        max-width: 80%;
    }
    @media (max-width: 820px) {
        max-width: 100%;
    }
    @media (max-width: 500px) {
        font-size: 11px;
        line-height: 20px;
        margin-bottom: 40px;
    }
    @media (max-height: 400px) {
        margin-bottom: 20px;
    }
`;

export const SearchContainer = styled.div<{ error: string; dir: string }>`
    display: flex;
    width: 100%;
    height: 80px;
    position: relative;
    @media (max-width: 1120px) {
        height: 70px;
    }
    @media (max-width: 820px) {
        height: 60px;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        height: auto;
        align-items: center;
    }

    ${({ error, dir }) =>
        error.length
            ? `::after {
        content: '${error}';
        position: absolute;
        top: 100%;
        text-align: left;
        font: normal normal normal 14px/30px Open Sans;
        letter-spacing: 0px;
        color: #EB0136;
        opacity: 1;
        ${dir === 'rtl' ? 'right: 8px;' : 'left: 8px;'}
        @media (max-width: 500px) {
          top: 50px;
          ${dir === 'rtl' ? 'right: 2px;' : 'left: 2px;'}
          font: normal normal normal 12px/30px Open Sans;
        }
      }`
            : null};
`;

export const SearchField = styled.input<{ error: string }>`
    color: #4d4d4d;
    line-height: 30px;
    font-size: 20px;
    padding: 0 25px;
    background: #ffffff;
    box-shadow: 0px 3px 20px #0000002f;
    -webkit-box-box-shadow: 0px 3px 20px #0000002f;
    -moz-box-box-shadow: 0px 3px 20px #0000002f;
    -webkit-appearance: none;
    margin-right: 20px;
    flex-grow: 1;
    border: 1px solid transparent;
    outline: none;
    min-width: 0;
    ::placeholder {
        color: #8e8e8e;
    }

    @media (max-width: 820px) {
        font-size: 16px;
    }
    @media (max-width: 500px) {
        min-height: 50px;
        margin-bottom: 55px;
        margin-right: 0;
        font-size: 14px;
        width: 100%;
    }

    &[dir='rtl'] {
        margin-left: 20px;
        margin-right: 0;
        @media (max-width: 500px) {
            margin-left: 0;
        }
    }

    ${({ error }) => (error.length ? 'border: 1px solid #EB0136' : null)};
`;

export const SearchButton = styled.button`
    line-height: 30px;
    font-size: 20px;
    background: #122a4c;
    width: 200px;
    border: none;
    outline: none;
    color: #ffffff;
    height: 100%;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    min-width: 120px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 820px) {
        font-size: 18px;
    }
    @media (max-width: 500px) {
        min-height: 40px;
        width: 123px;
        font-size: 16px;
        /* margin-bottom: 90px; */
    }
    ${({ disabled }) => (disabled ? 'cursor: not-allowed; background: #122A4C; opacity: 0.3' : null)}
`;

export const LanguageContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;

    &[dir='rtl'] {
        left: auto;
        right: 5px;
    }
`;
