import { batchTypes } from '../types/batch.types';

interface IInitialState {
    auth: boolean;
    batch: boolean;
    types: boolean;
    document: boolean;
}

const initialState: IInitialState = {
    auth: false,
    batch: false,
    types: false,
    document: false
};

const loaderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        // BATCH
        case batchTypes.BATCH_SEARCH_REQUEST: {
            return {
                ...state,
                batch: true
            };
        }
        case batchTypes.BATCH_SEARCH_SUCCESS:
        case batchTypes.BATCH_SEARCH_RESET:
        case batchTypes.BATCH_SEARCH_FAILURE: {
            return {
                ...state,
                batch: false
            };
        }
        // TYPES
        case batchTypes.BATCH_GET_DOCTYPES_REQUEST: {
            return {
                ...state,
                types: true
            };
        }
        case batchTypes.BATCH_GET_DOCTYPES_SUCCESS:
        case batchTypes.BATCH_GET_DOCTYPES_FAILURE: {
            return {
                ...state,
                types: false
            };
        }
        default:
            return state;
    }
};

export default loaderReducer;
