import React from 'react';
import * as Styled from './style';
import Empty from '../../../../assets/icons/empty_batch.svg';
import Arrow from '../../../../assets/icons/left_arrow.svg';
import { useSelector } from 'react-redux';
import { batchDataSelector } from '../../../../store/selectors/batch.selectors';
import { useHistory } from 'react-router-dom';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useDirection } from '../../../../hooks/useDirection';

export const EmptyState = () => {
    const batch = useSelector(batchDataSelector);
    const history = useHistory();
    const { t } = useCustomTranslation('');
    const direction = useDirection();
    const backHandler = () => history.push('/search');

    return (
        <Styled.ContentWrapper>
            <Styled.FixedContainer dir={direction}>
                <Styled.BackButton dir={direction} onClick={backHandler}>
                    <Styled.BackIcon dir={direction} src={Arrow} alt="" />
                    <Styled.BackText>{t('New Batch Number')}</Styled.BackText>
                </Styled.BackButton>
                <Styled.Number dir={direction}>
                    <Styled.NumberLabel dir={direction}>{t('Batch Number')}:</Styled.NumberLabel>
                    <Styled.NumberValue>{batch?.batch || ''}</Styled.NumberValue>
                </Styled.Number>
            </Styled.FixedContainer>
            <Styled.Content>
                <Styled.ContentTitle dir={direction}>{t('Empty Docs State')}</Styled.ContentTitle>
                <Styled.ContentSubTitle dir={direction}>{t('Wait for add docs')}</Styled.ContentSubTitle>
                <Styled.ContentImage alt="" src={Empty} />
            </Styled.Content>
        </Styled.ContentWrapper>
    );
};
