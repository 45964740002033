import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #122a4c;
    display: flex;
    @media (max-width: 800px) {
        flex-direction: column-reverse;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: calc(100% - 70px);
    background: #fafafa;
    display: flex;
    flex-grow: 1;
`;
