import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/batch.action';
import request from '../../helpers/request';
import { batchTypes } from '../types/batch.types';

function* searchByNumber(action: { [key: string]: any }) {
    try {
        const { callback } = action.payload;
        const { data } = yield call(request, action.request);
        yield put(actions.batchSearchSuccess(data));
        yield callback(data.batch);
    } catch (e) {
        const { data } = e.response;
        if (data?.detail?.includes('Not found')) {
            yield put(actions.batchSearchFailure('Invalid batch number, please try again'));
        }
    }
}

function* gerDocTypes(action: { [key: string]: any }) {
    try {
        const { data } = yield call(request, action.request);
        yield put(actions.batchGetDocTypesSuccess(data));
    } catch (e) {
        const { data } = e.response;
        yield put(actions.batchGetDocTypesFailure(data.detail));
    }
}

function* searchSaga() {
    yield takeLatest(batchTypes.BATCH_SEARCH_REQUEST, searchByNumber);
    yield takeLatest(batchTypes.BATCH_GET_DOCTYPES_REQUEST, gerDocTypes);
}

export default searchSaga;
