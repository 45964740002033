import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setLocale } from '../../helpers/setLocale';
import { dictionaryDataSelector } from '../../store/selectors/dictionary.selector';
import * as Styled from './style';

export const LanguageManager = () => {
    const lang_code = localStorage.getItem('lang_code') || 'en';
    const { i18n } = useTranslation();
    const [selectedValue, setSelectedValue] = useState(lang_code);
    const dictionaries = useSelector(dictionaryDataSelector);
    const languages = useMemo(() => Object.keys(setLocale(dictionaries)), [dictionaries]);

    const changeHandler = (name: string) => {
        setSelectedValue(name);
        i18n.changeLanguage(name);
        localStorage.setItem('lang_code', name);
    };

    return (
        <Styled.Container>
            {languages.map((name: string, i: number) => (
                <Fragment key={name}>
                    {i !== 0 && <Styled.Separator />}
                    <Styled.Value
                        className={selectedValue === name ? 'active' : ''}
                        onClick={() => changeHandler(name)}
                    >
                        {name.toUpperCase()}
                    </Styled.Value>
                </Fragment>
            ))}
        </Styled.Container>
    );
};
