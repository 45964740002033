import { createSelector } from 'reselect';

export const loaderSelector = (state: any) => state.loaderReducer;

export const authLoaderSelector = createSelector(loaderSelector, (loaders) => loaders.auth || false);

export const batchLoaderSelector = createSelector(loaderSelector, (loaders) => loaders.batch || false);

export const docsLoaderSelector = createSelector(loaderSelector, (loaders) => loaders.types || loaders.batch);

export const documentLoaderSelector = createSelector(loaderSelector, (loaders) => loaders.document);
