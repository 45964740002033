import { combineReducers } from 'redux';
import loaderReducer from './loader.reducer';
import batchReducer from './batch.reducer';
import dictionaryReducer from './dictionary.reducer';

export const reducers = combineReducers({
    loaderReducer,
    batchReducer,
    dictionaryReducer
});
