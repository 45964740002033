import React, { useEffect, useState } from 'react';
import * as Styled from './style';
import Arrow from '../../../../assets/icons/left_arrow.svg';
import File from '../../../../assets/icons/file.svg';
import { useSelector } from 'react-redux';
import { batchDataSelector, batchDocTypesSelector } from '../../../../store/selectors/batch.selectors';
import { useHistory } from 'react-router-dom';
import { IBatchDocument, IDocType, IBatchVideo } from '../../../../interfaces/batch.interface';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useDirection } from '../../../../hooks/useDirection';
import { LanguageManager } from '../../../../shared/LanguageManager';

interface Props {
    successCaptcha: boolean;
}

export const Sidebar: React.FC<Props> = ({ successCaptcha }) => {
    const batch = useSelector(batchDataSelector);
    const docTypes = useSelector(batchDocTypesSelector);
    const history = useHistory();
    const { t } = useCustomTranslation('');
    const direction = useDirection();
    const [docsByType, setDocsByType] = useState<IBatchDocument[][]>([]);

    useEffect(() => {
        const objWithDocs = batch?.documents.reduce(
            (result: [IBatchDocument[]], file: IBatchDocument) => ({
                ...result,
                [file.document_type]: result[file.document_type] ? [...result[file.document_type], file] : [file]
            }),
            {}
        );
        setDocsByType(Object.values(objWithDocs));
    }, []);

    const backHandler = () => history.push('/search');

    const docsNum = batch?.documents?.length;

    const renderDocs = () => {
        return docsByType.map((docs: IBatchDocument[]) => {
            const title = docTypes.find((el: IDocType) => el.id === docs[0].document_type)?.type;
            const label = docTypes.find((el: IDocType) => el.id === docs[0].document_type)?.label;
            return (
                <Styled.Group dir={direction} key={title}>
                    <Styled.Title>{t(title || '')}</Styled.Title>
                    {docs.map((item: IBatchDocument, i: number) => (
                        <Styled.DocumentItem key={i} to={`/document/${batch?.batch}/${item.id}`}>
                            <Styled.DocImg dir={direction} src={File} alt="" />
                            <Styled.FileName dir={direction}>{`${t(label || '')} ${
                                docs.length > 1 ? i + 1 : ''
                            }`}</Styled.FileName>
                        </Styled.DocumentItem>
                    ))}
                </Styled.Group>
            );
        });
    };

    return (
        <Styled.Wrapper successCaptcha={successCaptcha}>
            <Styled.LanguageContainer dir={direction}>
                <LanguageManager />
            </Styled.LanguageContainer>
            <Styled.BackButton dir={direction} onClick={backHandler}>
                <Styled.BackIcon src={Arrow} alt="" dir={direction} />
                <Styled.BackText docsNum={docsNum}>{t('New Batch Number')}</Styled.BackText>
            </Styled.BackButton>
            <Styled.Number dir={direction}>
                <Styled.NumberLabel dir={direction}>{t('Batch Number')}:</Styled.NumberLabel>
                <Styled.NumberValue>{batch?.batch || ''}</Styled.NumberValue>
            </Styled.Number>
            {!!batch?.videos?.length && (
                <>
                    <Styled.Title>{t('Videos')}</Styled.Title>
                    <Styled.VideosWrapper dir={direction}>
                        {batch.videos.map((video: IBatchVideo) => (
                            <Styled.VideoUrlContainer key={video.id} dir={direction}>
                                <Styled.VideoUrl dir={direction} rel="noopener" href={video?.url} target="_blank">
                                    {t(video?.video_type?.label)}
                                </Styled.VideoUrl>
                            </Styled.VideoUrlContainer>
                        ))}
                    </Styled.VideosWrapper>
                </>
            )}
            <Styled.DocumentsContainer>{renderDocs()}</Styled.DocumentsContainer>
        </Styled.Wrapper>
    );
};
