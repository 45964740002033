import styled, { css } from 'styled-components';

interface ContentProps {
    isPdfShowInMobile: boolean;
}

export const Content = styled.div<ContentProps>`
    width: 78%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 760px) {
        position: absolute;
        width: 100%;
        ${({ isPdfShowInMobile }) =>
            isPdfShowInMobile
                ? css`
                      height: 100%;
                      background: rgba(0, 0, 0, 0.8);
                  `
                : css`
                      background: unset;
                      height: auto;
                      bottom: 0;
                      display: none;
                  `};
    }
`;

export const Wrapper = styled.div`
    overflow: auto;
    padding: 50px;
    display: flex;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 760px) {
        overflow: unset;
        position: relative;
    }
`;

export const ViewPDF = styled.div`
    margin: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1200px) {
        width: 75%;
    }
    @media (max-width: 850px) {
        width: 85%;
    }
    @media (max-width: 760px) {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .PDFDocument {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 760px) {
            max-height: 100%;
            height: auto;
            margin-block-start: 1.5rem;
        }

        .PDFPage {
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
            max-height: calc(100% - 40px);
            @media (max-width: 760px) {
                width: none;
                max-height: none;
                overflow: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                ::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .react-pdf__Page.PDFPage {
            @media (max-width: 760px) {
                position: unset !important;
            }
        }

        .react-pdf__Page__annotations.annotationLayer section {
            @media (max-width: 760px) {
                left: 0 !important;
                height: 60px !important;
                width: 100% !important;
                top: 45% !important;
            }
        }

        .PDFPage > canvas {
            height: auto !important;
            @media (max-width: 760px) {
                width: 100% !important;
                max-height: none;
                width: 100%;
            }
        }
    }
`;

export const Controls = styled.div`
    display: flex;
    font-size: 1.4rem;
    width: 100%;
    justify-content: center;
    background: #3d464d;
    border-radius: 0 0 4px 4px;
    padding: 8px;
    color: #7a7d80;
    min-height: 40px;
`;

export const CloseButton = styled.div`
    position: fixed;
    top: 27px;
    left: 85%;
    color: #fff;
    font-size: 2rem;
    display: none;
    @media (max-width: 760px) {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }

    @media (max-width: 500px) {
        top: 1%;
        right: 7%;
    }
`;

export const ControlPageContainer = styled.div`
    display: flex;
    margin: 0 5px;
    height: 25px;
    align-items: center;
`;

export const ControlPageChange = styled.div`
    display: flex;
    cursor: pointer;
    width: 25px;
    background: #31383e;
    height: 100%;

    ::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        content: '';

        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }
    &.prev {
        position: relative;
        border-radius: 3px 0 0 3px;
        margin-left: 5px;
    }
    &.next {
        position: relative;
        border-radius: 0 3px 3px 0;
        margin-right: 5px;
    }
    &.prev::after {
        border-right: 6px solid #fff;
    }
    &.next::after {
        border-left: 6px solid #fff;
    }
`;

export const CurrentPage = styled.div`
    width: 50px;
    background: #31383e;
    margin: 0 2px;
    user-select: none;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Indicator = styled.div`
    display: flex;
    margin: 0 10px;
`;

export const IndicatorPage = styled.div`
    display: flex;
`;

export const IndicatorSeparator = styled.div`
    display: flex;
`;

export const ZoomIndicators = styled.div`
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    @media (max-width: 1024px) {
        display: none;
    }
`;

export const ZoomIcon = styled.img`
    height: 100%;
    margin: 5px;
`;
