import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #122a4c;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    transition: 0.3s ease-in;
    z-index: 9999;
    @media (max-width: 800px) {
        flex-direction: column-reverse;
    }
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 20px;
    left: 85%;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    @media (max-width: 760px) {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        color: crimson;
        font-size: 2.5rem;
        margin-bottom: 1rem;
        @media (max-width: 500px) {
            font-size: 1.4rem;
        }
    }

    a {
        color: #fff;
        font-size: 1.4rem;
        text-decoration: none;
    }
`;
