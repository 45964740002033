import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import * as Styled from './style';
import Logo from '../../assets/icons/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { batchSearchFailure, batchSearchRequest, batchSearchReset } from '../../store/actions/batch.action';
import { batchErrorSelector } from '../../store/selectors/batch.selectors';
import { useHistory } from 'react-router-dom';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { LanguageManager } from '../../shared/LanguageManager';
import { batchLoaderSelector } from '../../store/selectors/loader.selectors';
import { ButtonLoader } from '../../shared/Button/style';
import { useDirection } from '../../hooks/useDirection';

export const Search = () => {
    const [searchValue, setSearchValue] = useState<string>('');
    const dispatch = useDispatch();
    const error = useSelector(batchErrorSelector);
    const { t } = useCustomTranslation('');
    const history = useHistory();
    const direction = useDirection();
    const loader = useSelector(batchLoaderSelector);
    const isValid = /^.*$/.test(searchValue);
    const disabled = !isValid || !searchValue.trim();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value.trim());
        if (error) {
            dispatch(batchSearchFailure(''));
        }
    };

    useEffect(() => {
        dispatch(batchSearchReset());
        // eslint-disable-next-line
    }, []);

    const confirmHandler = () => {
        const searchTerm = searchValue.trim();
        if (!searchTerm) return;
        history.push(`/document/${searchTerm}`);
    };

    return (
        <Styled.Container>
            <Styled.ContentWrapper>
                <Styled.Content>
                    <Styled.LanguageContainer dir={direction}>
                        <LanguageManager />
                    </Styled.LanguageContainer>
                    <Styled.Logo src={Logo} alt="" />
                    <Styled.Title dir={direction}>{t('Enter Batch Number')}</Styled.Title>
                    <Styled.Subtitle dir={direction}>{t('Enter Batch Subtitle')}</Styled.Subtitle>
                    <Styled.SearchContainer dir={direction} error={error && t('Invalid Batch Number')}>
                        <Styled.SearchField
                            autoFocus
                            dir={direction}
                            placeholder={t('Enter Batch Placeholder')}
                            value={searchValue}
                            onChange={handleChange}
                            error={error && t('Invalid Batch Number')}
                        />
                        <Styled.SearchButton disabled={disabled || loader} onClick={confirmHandler}>
                            {loader ? <ButtonLoader style={{ height: 40 }} /> : t('Submit')}
                        </Styled.SearchButton>
                    </Styled.SearchContainer>
                </Styled.Content>
            </Styled.ContentWrapper>
            <Styled.ImageBlock />
        </Styled.Container>
    );
};
