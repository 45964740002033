import { IBatch, IDocType } from '../../interfaces/batch.interface';
import { batchTypes } from '../types/batch.types';

interface IInitialState {
    batch: IBatch | null;
    error: string;
    docTypes: IDocType[];
    recaptcha: string | null;
    docError: string | string[];
}

const initialState: IInitialState = {
    batch: null,
    error: '',
    docTypes: [],
    recaptcha: null,
    docError: ''
};

const batchReducer = (state = initialState, { payload, type }: any) => {
    switch (type) {
        case batchTypes.BATCH_SEARCH_REQUEST: {
            return {
                ...state,
                batch: null,
                error: ''
            };
        }
        case batchTypes.BATCH_SEARCH_FAILURE: {
            return {
                ...state,
                batch: null,
                error: payload
            };
        }

        case batchTypes.BATCH_SEARCH_RESET: {
            return {
                ...state,
                error: '',
                batch: null
            };
        }

        case batchTypes.BATCH_SEARCH_SUCCESS: {
            return {
                ...state,
                error: '',
                batch: payload
            };
        }

        case batchTypes.BATCH_GET_DOCTYPES_FAILURE: {
            return {
                ...state,
                docTypes: [],
                error: payload
            };
        }

        case batchTypes.BATCH_GET_DOCTYPES_SUCCESS: {
            return {
                ...state,
                error: '',
                docTypes: payload
            };
        }

        case batchTypes.BATCH_RECAPTCHA_UPDATE: {
            return {
                ...state,
                error: '',
                recaptcha: payload
            };
        }

        default:
            return state;
    }
};

export default batchReducer;
