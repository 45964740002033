import * as Styled from './style';
import React, { useState, FC, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseCross } from '../../../../assets/icons/white-cross.svg';
import { batchDataSelector } from '../../../../store/selectors/batch.selectors';
import { Loader, LocalizedLoader } from '../../../../shared/Loader';
import { documentLoaderSelector } from '../../../../store/selectors/loader.selectors';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useDirection } from '../../../../hooks/useDirection';
import ZoomInIcon from '../../../../assets/icons/zoom-in.svg';
import ZoomOutIcon from '../../../../assets/icons/zoom-out.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IMatchParams {
    document: string;
}
interface BatchDocument {
    batch: string;
    document_type: number;
    file: string;
    id: number;
}

interface Props {
    successCaptcha: boolean;
}

export const ViewFile: React.FC<Props> = ({ successCaptcha }) => {
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [scale, setScale] = useState<number>(1);
    const batch = useSelector(batchDataSelector);
    const [isPdfShowInMobile, setIsPdfShowInMobile] = useState<boolean>(true);
    const match = useRouteMatch<IMatchParams>();
    const loader = useSelector(documentLoaderSelector);
    const { t } = useCustomTranslation('');
    const direction = useDirection();
    const history = useHistory();
    const contentRef: any = useRef();
    const innerContentRef: any = useRef();

    useEffect(() => {
        setCurrentPage(1);
        setIsPdfShowInMobile(true);
    }, [match.params.document]);

    const document = React.useMemo(
        () => batch?.documents?.filter((doc: BatchDocument) => doc.id.toString() === match.params.document)[0].file,
        [batch, match.params.document]
    );

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setPages(numPages);
    };

    const handleChangePage = (type: string) => {
        if (type === 'next' && currentPage < pages) {
            setCurrentPage(currentPage + 1);
        }
        if (type === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const onCloseMobileModal = () => {
        history.push(`/document/${batch?.batch}`);
        setIsPdfShowInMobile(false);
    };

    const changeScale = (flag: string) => {
        const maxWidth = contentRef.current?.clientWidth - 180;
        flag === 'in'
            ? setScale((prevState) => {
                  return innerContentRef.current?.clientWidth < maxWidth ? prevState + 0.1 : prevState;
              })
            : setScale((prevState) => {
                  return prevState > 1 ? prevState - 0.1 : prevState;
              });
    };

    return (
        <Styled.Content dir="ltr" ref={contentRef} isPdfShowInMobile={isPdfShowInMobile}>
            {isPdfShowInMobile && (
                <Styled.CloseButton onClick={onCloseMobileModal}>
                    <CloseCross />
                </Styled.CloseButton>
            )}
            <Styled.Wrapper>
                {successCaptcha && (
                    <>
                        {loader ? (
                            <LocalizedLoader />
                        ) : (
                            <Styled.ViewPDF ref={innerContentRef}>
                                {pages > 0 && (
                                    <Styled.ZoomIndicators>
                                        <Styled.ZoomIcon src={ZoomInIcon} onClick={() => changeScale('in')} />
                                        <Styled.ZoomIcon src={ZoomOutIcon} onClick={() => changeScale('out')} />
                                    </Styled.ZoomIndicators>
                                )}
                                <Document
                                    className={'PDFDocument'}
                                    file={document}
                                    externalLinkTarget="_blank"
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={() => <Loader dir={direction} title={t('Please wait')} />}
                                >
                                    <Page
                                        scale={scale}
                                        className="PDFPage"
                                        pageNumber={currentPage}
                                        renderTextLayer={false}
                                        renderInteractiveForms={false}
                                    />
                                    <Styled.Controls>
                                        <Styled.ControlPageContainer>
                                            Page
                                            <Styled.ControlPageChange
                                                className="prev"
                                                onClick={() => handleChangePage('prev')}
                                            />
                                            <Styled.CurrentPage>{currentPage}</Styled.CurrentPage>
                                            <Styled.ControlPageChange
                                                className="next"
                                                onClick={() => handleChangePage('next')}
                                            />
                                            {`of ${pages}`}
                                        </Styled.ControlPageContainer>
                                    </Styled.Controls>
                                </Document>
                            </Styled.ViewPDF>
                        )}
                    </>
                )}
            </Styled.Wrapper>
        </Styled.Content>
    );
};
