import styled from 'styled-components';

export const ContentWrapper = styled.div`
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    justify-content: center;
    position: relative;
    background: #fafafa;
    flex-grow: 1;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 820px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 230px;
    padding: 0 40px;
    @media (max-width: 820px) {
        margin-top: 180px;
    }
`;

export const ContentTitle = styled.div`
    color: #848484;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 10px;
    text-align: center;
    @media (max-width: 820px) {
        font-size: 2rem;
    }
`;

export const ContentSubTitle = styled.div`
    color: #9b9b9b;
    font-weight: normal;
    font-size: 1.6rem;
    margin-bottom: 45px;
    text-align: center;
    @media (max-width: 820px) {
        font-size: 1.2rem;
    }
`;

export const ContentImage = styled.img`
    width: 420px;
    height: auto;
    margin-bottom: 40px;
    @media (max-width: 820px) {
        width: 70%;
        min-width: 200px;
    }
`;

export const ContentAddDocument = styled.label`
    background: #3b88b8;
    width: 195px;
    cursor: pointer;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
`;

export const ContentAddInput = styled.input`
    display: none;
`;

export const AddIcon = styled.img`
    width: 14px;
    height: auto;
    margin-right: 6px;
`;

export const FixedContainer = styled.div`
    top: 50px;
    left: 20px;
    position: absolute;
    @media (max-width: 820px) {
        top: 50px;
    }

    &[dir='rtl'] {
        left: auto;
        right: 20px;
    }
`;

export const BackButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom: 35px;
    @media (max-width: 1100px) {
        margin-bottom: 15px;
    }
`;

export const BackIcon = styled.img`
    width: 12px;
    height: auto;
    margin-right: 6px;

    &[dir='rtl'] {
        margin: 0 0 0 6px;
        transform: scale(-1);
    }
`;

export const BackText = styled.div`
    text-decoration: underline;
    font-size: 1.4rem;
    color: #122a4c;
    font-weight: 600;
    @media (max-width: 820px) {
        font-size: 1.2rem;
    }
`;

export const Number = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
`;
export const NumberLabel = styled.div`
    font-size: 2rem;
    color: #122a4c;
    margin-right: 4px;
    @media (max-width: 820px) {
        font-size: 1.4rem;
    }
    &[dir='rtl'] {
        margin: 0 0 0 6px;
    }
`;
export const NumberValue = styled.div`
    font-size: 2rem;
    color: #122a4c;
    font-weight: 600;
    @media (max-width: 820px) {
        font-size: 1.4rem;
    }
`;
