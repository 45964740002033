import React, { useRef } from 'react';
import * as Styled from './style';
import { useDispatch, useSelector } from 'react-redux';
import { batchRecaptchaUpdate, batchSearchRequest } from '../../../../store/actions/batch.action';
import { ReactComponent as CloseCross } from '../../../../assets/icons/white-cross.svg';
import Captcha from 'react-google-recaptcha';
import { Link, useHistory, useParams } from 'react-router-dom';
import { batchErrorSelector } from '../../../../store/selectors/batch.selectors';

export const Recaptcha = () => {
    const captchaRef = useRef<any>(null);
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const error = useSelector(batchErrorSelector);

    const onCaptchaChange = (value: string | null) => {
        dispatch(batchRecaptchaUpdate(value));
        dispatch(
            batchSearchRequest(id, value, () => {
                history.push(`/document/${id}`);
            })
        );
    };

    const handleGoBack = () => history.push('/search');
    return (
        <Styled.Container>
            <Styled.CloseButton onClick={handleGoBack}>
                <CloseCross />
            </Styled.CloseButton>
            {error ? (
                <Styled.ErrorContainer>
                    <h3>{error}</h3>
                    <Link to="/search">Search Again</Link>
                </Styled.ErrorContainer>
            ) : (
                <Captcha sitekey={process.env.REACT_APP_SITE_KEY || ''} onChange={onCaptchaChange} ref={captchaRef} />
            )}
        </Styled.Container>
    );
};
