import React, { useEffect, useState } from 'react';
import * as Styled from './style';
import { Sidebar } from './components/Sidebar';
import { ViewFile } from './components/ViewFile';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { EmptyState } from './components/EmptyState';
import { batchGetDocTypesRequest } from '../../store/actions/batch.action';
import { useDispatch, useSelector } from 'react-redux';
import { batchDataSelector, batchDocTypesSelector, batchRecapchaSelector } from '../../store/selectors/batch.selectors';
import { IBatchDocument } from '../../interfaces/batch.interface';
import { docsLoaderSelector } from '../../store/selectors/loader.selectors';
import { LocalizedLoader } from '../../shared/Loader';
import { useDirection } from '../../hooks/useDirection';
import { Recaptcha } from './components/Recaptcha';

export const Documents = () => {
    const captcha = useSelector(batchRecapchaSelector);
    const [successCaptcha, setSuccessCaptcha] = useState<boolean>(!!captcha);
    const match: any = useRouteMatch();
    const batch = useSelector(batchDataSelector);
    const types = useSelector(batchDocTypesSelector);
    const loading = useSelector(docsLoaderSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const direction = useDirection();

    useEffect(() => {
        const isDocExist = batch?.documents.find((el: IBatchDocument) => el.id === parseInt(match.params.document, 10));
        if (batch && !isDocExist) {
            history.push(`/document/${batch?.batch}/`);
        }
    }, [match.params.document, batch]);

    useEffect(() => {
        if (!types.length) {
            dispatch(batchGetDocTypesRequest());
        }
    }, []);

    const isEmpty = !batch?.documents.length;

    if (!batch) {
        return <Recaptcha />;
    }

    return (
        <Styled.Container>
            {loading ? (
                <LocalizedLoader />
            ) : isEmpty ? (
                <EmptyState />
            ) : (
                <Styled.Wrapper dir={direction}>
                    <Sidebar successCaptcha={successCaptcha} />
                    {match.params.document && <ViewFile successCaptcha={successCaptcha} />}
                </Styled.Wrapper>
            )}
        </Styled.Container>
    );
};
