import styled from 'styled-components';

export const Container = styled.div`
    padding: 5px 10px;
    display: flex;
    align-items: center;
`;

export const Separator = styled.div`
    width: 1px;
    height: 10px;
    background: #122a4c;
    margin: 0 10px;
`;

export const Value = styled.div`
    color: #122a4c;
    position: relative;
    cursor: pointer;
    font-size: 1.2rem;

    &.active {
        font-weight: 800;
    }

    &:last-child {
        margin-right: 0;
    }
`;
