export enum batchTypes {
    BATCH_SEARCH_REQUEST = 'BATCH_SEARCH_REQUEST',
    BATCH_SEARCH_SUCCESS = 'BATCH_SEARCH_SUCCESS',
    BATCH_SEARCH_FAILURE = 'BATCH_SEARCH_FAILURE',
    BATCH_SEARCH_RESET = 'BATCH_SEARCH_RESET',

    BATCH_GET_DOCTYPES_REQUEST = 'BATCH_GET_DOCTYPES_REQUEST',
    BATCH_GET_DOCTYPES_SUCCESS = 'BATCH_GET_DOCTYPES_SUCCESS',
    BATCH_GET_DOCTYPES_FAILURE = 'BATCH_GET_DOCTYPES_FAILURE',

    BATCH_GET_DOCUMENT_REQUEST = 'BATCH_GET_DOCUMENT_REQUEST',

    BATCH_RECAPTCHA_UPDATE = 'BATCH_RECAPTCHA_UPDATE'
}
