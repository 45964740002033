import { Documents } from '../views/Documents';
import { ViewFile } from '../views/Documents/components/ViewFile';
import { Search } from '../views/Search';

export const routes = [
    {
        name: 'Search',
        component: Search,
        isPrivate: false,
        exact: true,
        path: '/search'
    },
    {
        name: 'Documents List',
        component: Documents,
        exact: true,
        isPrivate: false,
        path: '/document/:id'
    },
    {
        name: 'Document',
        component: Documents,
        exact: true,
        isPrivate: false,
        path: '/document/:id/:document'
    }
];
